import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import { Container, Row, Col } from 'reactstrap'
import FindADentist from '../components/find-dentist'
import '../styles/global.scss'
import '../styles/index.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Lumineers Dental Veneers | #1 Patient-Requested Porcelain Veneer"
        description="Lumineers dental veneers are the #1 patient-requested porcelain veneer. Lumineers are placed by dentists trained in painless minimally invasive techniques."
      />
      <Container>
        <Row className="vid-background mt-5">
          <Col md="12">
            <StaticImage
              src="../images/Lumineers-group-1600x751.jpg"
              alt="Group photo"
              placeholder="none"
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className="intro-container text-center mt-5 mb-5">
              <h1 className="mb-5">
                Join the Lumineers<sup>&reg;</sup> Community
              </h1>
              <Link to="/results/" className="intro-button">
                Lumineers Lifestyle
              </Link>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="smiles-container">
        <Row>
          <Col md="6 my-auto">
            <div className="intro-container text-center mt-5 mb-5">
              <h2>Improve your smile.</h2>
              <h2>Change your life.</h2>
              <p className="mb-3 p-4">
                Lumineers<sup>&reg;</sup> have been described as life-changing.
                Patients have reported improvements in self-confidence,
                happiness and health.
              </p>
              <Link to="/results/" className="intro-button">
                Lumineers Stories
              </Link>
            </div>
          </Col>
          <Col md="6">
            <div className="intro-container text-center mt-5 mb-5">
              <StaticImage
                src="../images/Woman-Confident-Lumineers-800x800.jpg"
                alt="Woman smiling"
                placeholder="none"
                layout="fullWidth"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className="text-center mt-5 mb-5">
              <h1 className="mb-3">
                Lumineers are the #1 Patient-Requested Dental Veneer
              </h1>
              <p>
                If your teeth are stained, crooked, misaligned, misshapen, have
                gaps or you are simply unhappy with they way they look,
                Lumineers may be the right choice for you. Lumineers dental
                veneers can be applied seamlessly over your existing teeth to
                create a beautiful natural-looking smile. Lumineers are placed
                by dentists trained in painless minimally invasive techniques
                that typically require no shots or aggressive drilling.
                Lumineers are fabricated exclusively by DenMat Lab in Lompoc,
                California, USA, by technicians that specialize in crafting
                esthetic dental veneers.{' '}
              </p>
            </div>
            <div className="mb-5 text-center">
              <Link to="/faq" className="intro-button mb-5">
                Lumineers FAQ
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <FindADentist></FindADentist>
    </Layout>
  )
}

export default IndexPage
