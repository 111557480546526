import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

const findADentist = () => {
  return (
    <div style={{ backgroundColor: `#e8e8e8` }}>
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <div className="text-center mt-5 mb-5">
              <h2>Ask Your Dentist</h2>
              <p className="mb-5">
                Ask your dentist if Lumineers are right for you. If you do not
                have a dentist, view our growing directory of select dentists
                that may be in your area. Choosing a dentist is a personal
                decision and we encourage you to consult with several dentists
                to find a practice that meets the needs of your unique oral
                health situation.
              </p>
              <Link to="/find-a-dentist" className="intro-button">
                Find a Dentist
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default findADentist
